.action-snackbar-toast {
  background: transparent !important;
  box-shadow: none !important;
  min-height: unset !important;
  padding: 0 !important;
}

.Toastify__toast-container {
  max-width: 100% !important;
  min-width: var(--toastify-toast-width);
  width: unset !important;
}

.action-snackbar-toast .Toastify__toast-body {
  padding: 0 !important;
}

.action-snackbar-toast .Toastify__close-button {
  color: #fff !important;
  position: absolute;
  right: 5px;
  top: 5px;
}

.Toastify__progress-bar-theme--light {
  background: rgba(51, 48, 48, 0.15) !important;
}